import React from 'react'

import Layout from '../components/Layout/'
import SEO from '../components/Seo'
import SocialLinks from '../components/SocialLinks'

import { MainContent } from '../styles/base'

const NowPage = () => (
  <Layout>
    <SEO
      title="Now"
      description=""
    />
    <MainContent>
      <h1>Now</h1>
      https://nownownow.com/about
    </MainContent>
  </Layout>
)

export default NowPage
