const links = [
  {
    label: 'Github',
    url: 'https://github.com/gutobenn'
  },
  {
    label: 'Linkedin',
    url: 'https://linkedin.com/in/abennemann'
  },
  {
    label: 'Mail',
    url: 'mailto:me+website@augusto.dev'
  },
]

export default links
