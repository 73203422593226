import { Github } from '@styled-icons/boxicons-logos/Github'
import { Twitter } from '@styled-icons/boxicons-logos/Twitter'
import { Youtube } from '@styled-icons/boxicons-logos/Youtube'
import { Unsplash } from '@styled-icons/boxicons-logos/Unsplash'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin'
import { Mail } from '@styled-icons/entypo/Mail'

const Icons = {
  Github: Github,
  Twitter: Twitter,
  Youtube: Youtube,
  Unsplash: Unsplash,
  Instagram: Instagram,
  Linkedin: Linkedin,
  Mail: Mail
}

export default Icons
